import {
  //Discord,
  Facebook,
  Instagram,
  Rss,
  Twitch,
  Twitter,
  Youtube,
} from '@icons-pack/react-simple-icons'

export const SITE_DESCRIPTION =
  'Stat(ing) My Mind... - Homepage pessoal do blogger, web designer e pessoa fantástica Luís Nabais.'

export const SITE_KEYWORDS = [
  'Luis',
  'Nabais',
  'Miguel',
  'Web',
  'Design',
  'Development',
  'anime',
  'Portugal',
  'Lisboa',
  'animation',
  'japan',
  'otaku',
]

export const TITLE_SEPARATOR = ' // '

export const TITLES = {
  SITE: 'NonsenseBB',
  ARCHIVES: 'Arquivos',
  CATEGORY: 'Categoria',
  CATEGORIES: 'Categorias',
  TAG: 'Tag',
  TAGS: 'Tags',
  CONTACTS: 'Contactos',
  NOT_FOUND: 'Página não encontrada',
  ERROR: 'Erro!',
  YEAR: 'Ano',
  RELATED: 'Entradas Relacionadas',
  PAGES: 'Páginas',
  NEXT: 'Anterior',
  PREV: 'Seguinte',
  CURRENTLY_PLAYING_GAME: 'A jogar…',
  CURRENTLY_READING_BOOK: 'A ler…',
  TOGGLE_SEARCH: 'Mostrar/Esconder a caixa de Pesquisa',
  SEARCH: 'Pesquisar',
}

export const PERMALINK_TITLE = (title) => `Link permanente para o post: ${title}`

export const BASE_URL = import.meta.env?.VITE_BASE_URL || '/'
export const BASE_WORDPRESS_URL = import.meta.env?.VITE_BASE_WORDPRESS_URL || BASE_URL
export const BASE_STATIC_URL = import.meta.env?.VITE_BASE_STATIC_URL || '/'

// works best if divisible by 3 and 2 (for desktop/mobile)
export const HOME_PAGE_SIZE = 12
export const ARCHIVE_PAGE_SIZE = 42

export const FOOTER_RELATED_ENTRIES_SIZE = 3
export const ARTICLE_RELATED_ENTRIES_SIZE = 6

export const TOP_TAG_COUNT = 50

export const PATHS = {
  HOME: '/',
  ARCHIVES: '/archives',
  YEAR_ARCHIVES: '/[year]',
  YEAR_ARCHIVES_PAGE: '/[year]/page/[page]',
  MONTH_ARCHIVES: '/[year]/[month]',
  MONTH_ARCHIVES_PAGE: '/[year]/[month]/page/[page]',
  DAY_ARCHIVES: '/[year]/[month]/[day]',
  DAY_ARCHIVES_PAGE: '/[year]/[month]/[day]/page/[page]',
  ARTICLES: '/[year]/[month]/[day]/[slug]',
  ARTICLES_PAGED: '/[year]/[month]/[day]/[slug]/[page]',
  CATEGORY_ARCHIVES: '/category/[...slugs]',
  TAG_ARCHIVES: '/tag/[...slugs]',
  ABOUT: '/about-me',
  CONTACTS: '/contactos',
  COOKIE_POLICY: '/politica-de-cookies',
  PAGES: '/page/[...slugs]',
}

export const MENU_ENTRIES = [
  {
    path: PATHS.HOME,
    label: 'Home',
    internal: true,
  },
  {
    path: PATHS.ARCHIVES,
    label: 'Arquivos',
    internal: true,
  },
  {
    path: PATHS.ABOUT,
    label: 'Sobre',
    internal: true,
  },
//  {
//    path: PATHS.CONTACTS,
//    label: 'Contactos',
//    internal: true,
//  },
]

export const FOOTER_LINKS = [
  {
    id: 'rss',
    href: `${BASE_WORDPRESS_URL}/feed`,
    IconComponent: Rss,
    label: 'RSS',
  },
  {
    id: 'twitter',
    href: 'https://twitter.com/NonsenseBB',
    IconComponent: Twitter,
    label: 'Twitter',
  },
  {
    id: 'facebook',
    href: 'https://www.facebook.com/NonsenseBB/',
    IconComponent: Facebook,
    label: 'Facebook',
  },
  {
    id: 'youtube',
    href: 'https://www.youtube.com/channel/UCHIIUIt7pK5BzY157t4PjCA/',
    IconComponent: Youtube,
    label: 'YouTube',
  },
  {
    id: 'twitch',
    href: 'https://www.twitch.tv/nonsensebb',
    IconComponent: Twitch,
    label: 'Twitch',
  },
  {
    id: 'instagram',
    href: 'https://www.instagram.com/Nonsense_BB/',
    IconComponent: Instagram,
    label: 'Instagram',
  },
  //{
  //  id: 'discord',
  //  href: 'https://discord.gg/RR8EnRFRQG',
  //  IconComponent: Discord,
  //  label: 'Discord',
  //},
]

export const FB_AUTHOR_URL = import.meta.env?.VITE_FB_AUTHOR_URL
export const FB_PAGE_URL = import.meta.env?.VITE_FB_PAGE_URL

export const LARGE_SOCIAL_IMAGE_WIDTH = 800
export const ENABLE_TRANSFORMED_IMAGES = import.meta.env?.VITE_ENABLE_TRANSFORMED_IMAGES !== 'false'

export const EXCERPT_CHARACTER_LIMIT = 600

export const SERVER_TIMEZONE = 'Europe/Lisbon'

export const SEARCH_URL = `https://www.google.com/search?q=${encodeURIComponent(`site:${BASE_WORDPRESS_URL}`)}`
