import { h } from 'preact'
import classNames from 'clsx'

import { getDisplayName } from '../../utils'

import styles from './index.module.scss'

const SiteContent = ({ className, children, Element = 'div', ...props }) => (
  <Element className={classNames(className, styles['m-site-content'])} {...props}>
    {children}
  </Element>
)

export const SiteContentEntry = AsSiteContentEntry('section')

export function AsSiteContentEntry(Component) {
  const WrappedComponent = ({ children, ...props }) => {
    const {
      className,
      withMargin,
      noMargin,
      marginDesktop,
      textMargin,
      ...rest
    } = props

    return (
      <Component
        {...rest}
        className={buildEntryClassNames({
          className,
          withMargin,
          noMargin,
          marginDesktop,
          textMargin,
        })}
      >
        {children}
      </Component>
    )
  }

  WrappedComponent.displayName = `AsSiteContentEntry(${getDisplayName(Component)})`

  return WrappedComponent
}

function buildEntryClassNames({ className, withMargin, noMargin, marginDesktop, textMargin }) {
  return classNames(
    className,
    styles['m-site-content__entry'],
    {
      [styles['m-site-content__entry--margin']]: withMargin,
      [styles['m-site-content__entry--no-margin']]: noMargin,
      [styles['m-site-content__entry--margin-desktop']]: marginDesktop,
      [styles['m-site-content__entry--text-margin']]: textMargin,
    },
  )
}

export default SiteContent
